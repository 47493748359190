import { ThemeProvider, makeStyles } from '@mui/styles'
import React from 'react'
import { getTheme } from '~style'
import { StyledEngineProvider } from '@mui/material'

import '../../index.css'
import { CustomFormsBrander } from './CustomFormsBrander'
import { useQueryParams } from '../routing/hooks/useQueryParams'

const GlobalStyles = ({ children }) => {
  useStyles()

  return children
}

export const CustomFormsRoot = () => {
  useStyles()
  const { eventId } = useQueryParams()

  const theme = getTheme()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles>
          <CustomFormsBrander eventId={eventId} />
        </GlobalStyles>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    'body, #root': {
      height: '100%',
      margin: 0,
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))
